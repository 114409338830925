/* homePage.css */
.body {
    margin: 0;
}

.home-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay-box {
    /*margin-top: 10px;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    padding: 15px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /*width: 80%;*/
    /*max-width: 600px;*/
    /*max-height: 800px; !* Set the maximum height *!*/
    /*overflow-y: auto; !* Enable vertical scrollbar if content exceeds the height *!*/
}
.box-accident{
    padding: 15px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width:30%
}

@media (max-width: 600px) {
    .overlay-box {
        width: 90%;
    }
}


.MuiContainer-root {
    min-height: 0;
    max-width: none !important;
    padding-left: 0 !important;
}


.background-img {
    height: 100%; /* Adjust the height percentage to your preference */
}
